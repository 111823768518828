import { useSidebarStore } from '@/store'
import { Icon } from '@iconify/react'

export default function CollapseMenu() {
  const { collapsed, setCollapsed } = useSidebarStore()
  return (
    <Icon
      onClick={() => setCollapsed(!collapsed)}
      className="bg-default text-zinc-300 hover:text-linkby-purple cursor-pointer absolute z-[99999] -right-[0px] top-1/2 h-[30px] w-[20px] -translate-y-1/2  rounded-l-[4px] transition-all duration-150 dark:bg-default-100 hover:dark:bg-primary hover:dark:text-gray-900"
      icon={`flowbite:chevron-double-${collapsed ? 'right' : 'left'}-outline`}
    />
  )
}
