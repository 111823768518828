'use client'
import { LinkbyLogo } from '@/components/svg'
const LayoutLoader = () => {
  return (
    <div className="h-screen flex items-center justify-center flex-col space-y-2">
      <LinkbyLogo className="h-48 w-48 text-primary" />
    </div>
  )
}

export default LayoutLoader
