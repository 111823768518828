import { siteConfig } from '@/config/site.config'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { useSidebarStore } from './sidebar.store'

interface ThemeStoreState {
  theme: string
  setTheme: (theme: string) => void
  radius: number
  setRadius: (value: number) => void
  layout: string
  setLayout: (value: string) => void
  navbarType: string
  setNavbarType: (value: string) => void
  footerType: string
  setFooterType: (value: string) => void
  showThemeCustomizer: boolean
  setShowThemeCustomizer: (value: boolean) => void
}

export const useThemeStore = create<ThemeStoreState>()(
  persist(
    (set) => ({
      theme: siteConfig.theme,
      setTheme: (theme) => set({ theme }),
      radius: siteConfig.radius,
      setRadius: (value) => set({ radius: value }),
      layout: siteConfig.layout,
      setLayout: (value) => {
        set({ layout: value })
        if (value === 'semibox') {
          useSidebarStore.setState({ sidebarType: 'popover' })
        }
        if (value === 'horizontal') {
          useSidebarStore.setState({ sidebarType: 'classic' })
          useThemeStore.setState({ navbarType: 'sticky' })
        }
      },
      navbarType: siteConfig.navbarType,
      setNavbarType: (value) => set({ navbarType: value }),
      footerType: siteConfig.footerType,
      setFooterType: (value) => set({ footerType: value }),
      showThemeCustomizer: false,
      setShowThemeCustomizer: (value) => set({ showThemeCustomizer: value }),
    }),
    { name: 'theme-store', storage: createJSONStorage(() => localStorage) },
  ),
)
