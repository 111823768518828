import { generateBreadcrumbs, getDynamicPath } from '@/lib/utils'
import { useHeaderTopNavigatorStore } from '@/store/header-top-navigator.store'
import { Home } from 'lucide-react'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { BreadcrumbItem, Breadcrumbs } from '../ui/breadcrumbs'

const TopNavigator = () => {
  const pathname = usePathname()
  const locationName = getDynamicPath(pathname)

  const searchParams = useSearchParams()

  const breadCrumbState = useHeaderTopNavigatorStore()

  useEffect(() => {
    const paramsObject = Object.fromEntries(searchParams.entries())
    breadCrumbState.setRouteQueryParams({
      ...(breadCrumbState.routeQueryParams || {}),
      [pathname]: paramsObject,
    })
  }, [searchParams])

  return (
    <>
      <Breadcrumbs className="font-bold">
        <BreadcrumbItem href="/">
          <Home className="size-4" />
        </BreadcrumbItem>
        {generateBreadcrumbs(
          locationName.split('/').filter((item: any) => item),
        ).map((item, index) => (
          <BreadcrumbItem
            key={index}
            href={
              item.href
                ? `${item.href}?${new URLSearchParams(breadCrumbState?.routeQueryParams?.[item.href] || {}).toString()}`
                : null
            }
            className="capitalize text-xs text-default-600 select-none">
            {item.name}
          </BreadcrumbItem>
        ))}
      </Breadcrumbs>
    </>
  )
}

export default TopNavigator
