'use client'
import { cn } from '@/lib/utils'
import { Icon } from '@iconify/react'

const SubMenuHandler = ({
  item,
  toggleSubmenu,
  index,
  activeSubmenu,
  collapsed,
}: {
  item: any
  toggleSubmenu: any
  index: number
  activeSubmenu: number | null
  collapsed: boolean
}) => {
  const { title, disabled } = item

  return (
    <>
      {collapsed ? (
        <div
          className={cn(
            'inline-flex cursor-pointer items-center justify-center w-12 h-12 rounded-md',
            disabled
              ? ''
              : 'data-[state=open]:bg-primary-100 data-[state=open]:text-primary',
          )}>
          <Icon icon={item.icon} className="w-6 h-6" />
        </div>
      ) : (
        <div
          onClick={() => !disabled && toggleSubmenu(index)}
          className={cn(
            'flex  text-default-700 font-medium text-sm capitalize px-[10px] py-3 rounded cursor-pointer transition-all duration-100 group',
            disabled
              ? 'cursor-not-allowed text-default-700/50'
              : 'cursor-pointer hover:bg-primary hover:text-primary-foreground',
            {
              'bg-primary  text-primary-foreground': activeSubmenu === index,
            },
          )}>
          <div className="flex-1  gap-3 flex items-start">
            <span className="inline-flex items-center  text-lg ">
              <Icon icon={item.icon} className="w-5 h-5" />
            </span>
            <div className="">{title}</div>
          </div>
          <div className="flex-0">
            <div
              className={cn(
                ' text-base rounded-full flex justify-center items-center transition-all duration-300 group-hover:text-primary-foreground',
                {
                  'rotate-90  ': activeSubmenu === index,
                  ' text-default-500  ': activeSubmenu !== index,
                },
              )}>
              <Icon
                icon="heroicons:chevron-right-20-solid"
                className="h-5 w-5"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SubMenuHandler
