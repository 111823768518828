'use client'

import { useMediaQuery } from '@/hooks/use-media-query'
import { useSidebarStore, useThemeStore } from '@/store'
import MobileSidebar from './mobile-sidebar'
import PopoverSidebar from './popover'

const Sidebar = () => {
  const { sidebarType, collapsed } = useSidebarStore()
  const { layout } = useThemeStore()

  const isDesktop = useMediaQuery('(min-width: 1280px)')

  let selectedSidebar = null

  if (!isDesktop && (sidebarType === 'popover' || sidebarType === 'classic')) {
    selectedSidebar = <MobileSidebar />
  } else {
    const sidebarComponents: { [key: string]: JSX.Element } = {
      popover: <PopoverSidebar />,
    }

    selectedSidebar = sidebarComponents[sidebarType]
  }

  return <div>{selectedSidebar}</div>
}

export default Sidebar
