'use client'

import Header from '@/components/header'
import LayoutLoader from '@/components/layout-loader'
import SearchComponent from '@/components/search'
import Sidebar from '@/components/sidebar'
import MobileSidebar from '@/components/sidebar/mobile-sidebar'
import { useMounted } from '@/hooks/use-mounted'
import { cn } from '@/lib/utils'
import { useUserQuery } from '@/services/users/query.service'
import { useSidebarStore } from '@/store'
import { motion } from 'framer-motion'
import { useState } from 'react'
const DashBoardLayoutProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { collapsed } = useSidebarStore()
  const [open, setOpen] = useState(false)
  const mounted = useMounted()
  const { data: user, isLoading: isUserLoading } = useUserQuery()

  if (isUserLoading) {
    return <LayoutLoader />
  }

  return (
    <>
      <Sidebar />
      <div
        className={cn(
          'content-wrapper transition-all duration-150 max-w-full',
          {
            'xl:pl-[248px]': !collapsed,
            'xl:pl-[72px]': collapsed,
          },
        )}>
        <div className={cn('page-min-height mx-auto max-w-[1280px]')}>
          <div className="px-[15px] 2xl:px-0">
            <Header />
            <LayoutWrapper setOpen={setOpen} open={open}>
              {children}
            </LayoutWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoardLayoutProvider

const LayoutWrapper = ({
  children,
  setOpen,
  open,
}: {
  children: React.ReactNode
  setOpen: any
  open: boolean
}) => {
  return (
    <>
      <motion.div
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            opacity: 0,
            y: 50,
          },
          pageAnimate: {
            opacity: 1,
            y: 0,
          },
          pageExit: {
            opacity: 0,
            y: -50,
          },
        }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.5,
        }}>
        <main>{children}</main>
      </motion.div>

      <MobileSidebar className="left-[300px]" />
      <SearchComponent open={open} setOpen={setOpen} />
    </>
  )
}
