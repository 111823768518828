'use client'

import { cn } from '@/lib/utils'
import { useSidebarStore, useThemeStore } from '@/store'

import { useMediaQuery } from '@/hooks/use-media-query'
import { useEffect } from 'react'
import ClassicHeader from './layout/classic-header'
import MobileMenuHandler from './mobile-menu-handler'
import ProfileInfo from './profile-info'
import TopNavigator from './top-navigator'

const NavTools = ({
  isDesktop,
  isMobile,
  sidebarType,
}: {
  isDesktop: boolean
  isMobile: boolean
  sidebarType: string
}) => {
  return (
    <div className="nav-tools flex items-center  gap-2">
      {/* {isDesktop && <FullScreen />} */}
      {!isDesktop && sidebarType !== 'module' && <MobileMenuHandler />}
      <ProfileInfo />
    </div>
  )
}
const Header = () => {
  const { collapsed, sidebarType, setSidebarType } = useSidebarStore()
  const { layout, navbarType } = useThemeStore()
  const isDesktop = useMediaQuery('(min-width: 1280px)')
  const isMobile = useMediaQuery('(min-width: 768px)')

  useEffect(() => {
    if (!isDesktop && layout === 'horizontal') {
      setSidebarType('classic')
    }
  }, [isDesktop, layout, setSidebarType])

  return (
    <ClassicHeader
      className={cn('', { 'sticky top-0': navbarType === 'sticky' })}>
      <div className="flex justify-between items-center h-full py-3 bg-card">
        <TopNavigator />
        <NavTools
          isDesktop={isDesktop}
          isMobile={isMobile}
          sidebarType={sidebarType}
        />
      </div>
    </ClassicHeader>
  )
}

export default Header
