'use client'
import { logout } from '@/actions/auth'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useUserQuery } from '@/services/users/query.service'
import { Icon } from '@iconify/react'
import { Avatar } from '@radix-ui/react-avatar'
import Link from 'next/link'
import { AvatarFallback, AvatarImage } from '../ui/avatar'

const ProfileInfo = () => {
  const { data: user, isLoading: isUserLoading } = useUserQuery()

  if (isUserLoading) {
    return null
  }

  return (
    <div className="flex justify-center items-center">
      <span className="mr-2">
        {user.firstName} {user.lastName}
      </span>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className=" cursor-pointer">
          <div className=" flex items-center  ">
            <Avatar className="w-9 h-9">
              {user.avatarUrl ? (
                <AvatarImage
                  src={user.avatarUrl}
                  alt={`${user.firstName} ${user.lastName}`}
                  width={36}
                  height={36}
                  className="rounded-full"
                />
              ) : (
                <AvatarFallback className="rounded-full bg-purple-500 p-2 text-white">
                  {user.firstName[0] + user.lastName[0]}
                </AvatarFallback>
              )}
            </Avatar>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 p-0" align="end">
          <DropdownMenuLabel className="flex gap-2 items-center mb-1 p-3">
            <Avatar className="w-9 h-9">
              {user.avatarUrl ? (
                <AvatarImage
                  src={user.avatarUrl}
                  alt={`${user.firstName} ${user.lastName}`}
                  width={36}
                  height={36}
                  className="rounded-full"
                />
              ) : (
                <AvatarFallback className="rounded-full bg-purple-500 p-2 text-white">
                  {user.firstName[0] + user.lastName[0]}
                </AvatarFallback>
              )}
            </Avatar>
            <div>
              <div className="text-sm font-medium text-default-800 capitalize ">
                {user.firstName} {user.lastName}
              </div>
              <Link
                href="/dashboard"
                className="text-xs text-default-600 hover:text-primary">
                {user.email}
              </Link>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuGroup>
            {[
              {
                name: 'Tech Support',
                icon: 'line-md:phone-call-loop',
                href: 'https://linkby.atlassian.net/servicedesk/customer/portal/1',
                blank: true,
              },
              {
                name: 'Campaign Dispute Form',
                icon: 'clarity:form-line',
                href: 'https://forms.gle/d6NJdPtsa363DgAZ6',
                blank: true,
              },
            ].map((item, index) => (
              <Link
                href={item.href}
                target={item.blank ? '_blank' : '_self'}
                key={`info-menu-${index}`}
                className="cursor-pointer">
                <DropdownMenuItem className="flex items-center gap-2 text-sm font-medium text-default-600 capitalize px-3 py-1.5 dark:hover:bg-background cursor-pointer">
                  <Icon icon={item.icon} className="w-4 h-4" />
                  {item.name}
                </DropdownMenuItem>
              </Link>
            ))}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onSelect={() => logout()}
            className="flex items-center gap-2 text-sm font-medium text-default-600 capitalize my-1 px-3 dark:hover:bg-background cursor-pointer">
            <Icon icon="line-md:logout" className="w-4 h-4" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
export default ProfileInfo
