'use client'
import { SonnToaster } from '@/components/ui/sonner'
import { Toaster as ReactToaster } from '@/components/ui/toaster'
import { cn } from '@/lib/utils'
import ReactQueryClientProvider from '@/providers/react-query.provider'
import { useThemeStore } from '@/store'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from 'next-themes'
import { Inter } from 'next/font/google'
import { Toaster } from 'react-hot-toast'
import DashBoardLayoutProvider from './dashboard-layout.provider'

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
})

const Providers = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useThemeStore()

  return (
    <ReactQueryClientProvider>
      <body
        className={cn(
          'linkby-admin-app overflow-y-scroll',
          inter.className,
          'theme-' + theme,
        )}>
        <ThemeProvider
          attribute="class"
          enableSystem={true}
          defaultTheme="light">
          <div className="h-full">
            <DashBoardLayoutProvider>{children}</DashBoardLayoutProvider>
            <ReactToaster />
          </div>
          <Toaster />
          <SonnToaster />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={true} buttonPosition="bottom-left" />
      </body>
    </ReactQueryClientProvider>
  )
}

export default Providers
