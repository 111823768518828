'use client'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils'
import { Icon } from '@iconify/react'
import * as HoverCard from '@radix-ui/react-hover-card'

import CollapsedHoverMenu from './collapsed-hover-menu'

const SubMenuHandler = ({
  item,
  toggleSubmenu,
  index,
  activeSubmenu,
  collapsed,
  menuTitle,
}: {
  item: any
  toggleSubmenu: any
  index: number
  activeSubmenu: number | null
  collapsed: boolean
  menuTitle?: string
}) => {
  const { title, disabled } = item

  return (
    <>
      {collapsed ? (
        <HoverCard.Root openDelay={0}>
          <HoverCard.Trigger asChild>
            <div
              className={cn(
                'inline-flex cursor-pointer items-center justify-center   w-12 h-12  rounded-md',
                disabled
                  ? 'cursor-not-allowed text-default-700/50'
                  : 'data-[state=open]:bg-primary-100 data-[state=open]:text-primary',
              )}>
              <Icon
                className={cn(
                  'w-[20px] h-[20px] flex items-center justify-center',
                  disabled ? 'opacity-50' : 'opacity-100',
                )}
                icon={item.icon}
              />
            </div>
          </HoverCard.Trigger>
          <HoverCard.Portal>
            {!disabled && (
              <HoverCard.Content
                collisionPadding={70}
                sideOffset={5}
                side="right"
                className="z-50  data-[side=bottom]:animate-slideUpAndFade data-[side=right]:animate-slideLeftAndFade data-[side=left]:animate-slideRightAndFade data-[side=top]:animate-slideDownAndFade w-[300px] rounded-md  bg-popover  shadow-sm data-[state=open]:transition-all">
                <ScrollArea
                  className={cn('p-5', {
                    'h-[250px]':
                      item.child.length > 5 ||
                      item.child.some(
                        (childItem: any) =>
                          childItem.multi_menu &&
                          childItem.multi_menu.length > 5,
                      ),
                  })}>
                  <CollapsedHoverMenu item={item} menuTitle={menuTitle} />
                </ScrollArea>
              </HoverCard.Content>
            )}
          </HoverCard.Portal>
        </HoverCard.Root>
      ) : (
        <div
          onClick={() => !disabled && toggleSubmenu(index)}
          className={cn(
            'flex  text-default-700 font-medium text-sm capitalize px-[10px] py-3 rounded cursor-pointer transition-all duration-100 group',
            disabled
              ? 'cursor-not-allowed text-default-700/50'
              : 'cursor-pointer hover:bg-primary hover:text-primary-foreground',
            {
              'bg-primary  text-primary-foreground': activeSubmenu === index,
            },
          )}>
          <div className="flex-1  gap-3 flex items-start">
            <span className="inline-flex items-center  text-lg ">
              <Icon
                className="w-[20px] h-[20px] flex items-center justify-center"
                icon={item.icon}
              />
            </span>
            <div className="">{title}</div>
          </div>
          <div className="flex-0">
            <div
              className={cn(
                ' text-base rounded-full flex justify-center items-center transition-all duration-300 group-hover:text-primary-foreground',
                {
                  'rotate-90  ': activeSubmenu === index,
                  ' text-default-500  ': activeSubmenu !== index,
                },
              )}>
              <Icon
                icon="heroicons:chevron-right-20-solid"
                className={cn('size-5', {
                  'cursor-not-allowed text-default-700/50': disabled,
                })}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SubMenuHandler
